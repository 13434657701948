import { Injectable } from '@angular/core';
import { AuthenticatedUser } from '@models/authentication.model';
import { FeaturesEnum, PermissionEnum } from '@shared/enums/permission.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagerService {
  private readonly _user$ = new BehaviorSubject<AuthenticatedUser | null>(null);
  private _user: AuthenticatedUser | null = null;

  get user() {
    return this._user;
  }

  get listener() {
    return this._user$.asObservable();
  }

  setUser(user: AuthenticatedUser | null) {
    this._user = !user ? null : user;

    this._user$.next(this._user);
  }

  editUser(user: Partial<AuthenticatedUser>) {
    this._user = {
      ...this._user,
      ...(user as AuthenticatedUser),
    };

    this._user$.next(this._user);
  }

  hasPermission(feature: FeaturesEnum, permissionType: PermissionEnum) {
    const permissionsSplited = this.user?.permissions.map((permission) =>
      permission.split('::')
    );

    if (!permissionsSplited) return false;

    for (const permission of permissionsSplited) {
      if (permission[0] === feature && permission[1] === permissionType) {
        return true;
      }
    }

    return false;
  }
}
